import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Header from "../components/Header";

const NotFound = () => {
  return (
    <React.Fragment>
      <Header enableBackBtn={true} />
      <Container>
        <Typography mt={3} variant="h4" component="h6">
          404 Page not found
        </Typography>
      </Container>
    </React.Fragment>
  )
};

export default NotFound;